import type { Router } from 'next/router';
import { useEffect } from 'react';

interface UseInterceptNextDataHrefProps {
  router: Router;
  pathPrefix: string;
}

export const useInterceptNextDataHref = ({ router, pathPrefix }: UseInterceptNextDataHrefProps) => {
  useEffect(() => {
    if (router.pageLoader?.getDataHref) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const originalGetDataHref = router.pageLoader.getDataHref;
      router.pageLoader.getDataHref = function (...args) {
        const r = originalGetDataHref.call(router.pageLoader, ...args);
        return r && r.startsWith('/_next/data') ? `/${pathPrefix}${r}` : r;
      };
    }
  }, [router, pathPrefix]);
};
