import { HeadManagerContext } from 'next/dist/shared/lib/head-manager-context';
import { useRouter } from 'next/router';
import type { ScriptProps } from 'next/script';
import Script from 'next/script';
import React, { useContext, useEffect } from 'react';

import type { HydraNextBus } from '@/hydra/next/getHydraNextBus';
import getHydraNextBus from '@/hydra/next/getHydraNextBus';

const hydraNextBus = getHydraNextBus();

const assetPrefix = '_marketing_assets';

declare global {
  interface Window {
    hydraNextBus: HydraNextBus;
  }
}

if (typeof window !== 'undefined' && !window.hydraNextBus) {
  // Make the hydraNextBus available globally for Hydra
  window.hydraNextBus = hydraNextBus;
}

interface RouteProps {
  shallow: boolean;
}

function useRouterEvents() {
  const router = useRouter();
  const hydraNextBus = getHydraNextBus();
  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: RouteProps) => {
      hydraNextBus.next.dispatchEvent(new CustomEvent('routeChangeStart', { detail: { url, shallow } }));
    };
    const handleRouteChangeComplete = (url: string, { shallow }: RouteProps) => {
      hydraNextBus.next.dispatchEvent(new CustomEvent('routeChangeComplete', { detail: { url, shallow } }));
    };
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events, hydraNextBus]);
}

export function getPlayground() {
  if (typeof window !== 'undefined' && process.env.HYDRA_LOCAL) {
    const params = new URLSearchParams(window.location.search);
    return params.get('p') || false;
  }
  return false;
}

// Workaround for https://github.com/vercel/next.js/issues/53651
function SafeScript({ id, src, strategy, ...restProps }: ScriptProps) {
  // Context is available only during SSR
  const context = useContext(HeadManagerContext);
  if (strategy === 'beforeInteractive' || strategy === 'worker') {
    if (context.updateScripts) {
      const scripts = context.scripts as Record<string, ScriptProps[]>;
      const existing = scripts[strategy] || [];
      if (existing.find((state) => (id ? state.id === id : state.src === src))) {
        return null;
      }
    }
  }
  return <Script id={id} src={src} strategy={strategy} {...restProps} />;
}

export default function HydraSnippet() {
  const router = useRouter();
  const basePath = ['', ...router.basePath.split('/').filter(Boolean), assetPrefix, ''].join('/');
  const isLocal = process.env.HYDRA_LOCAL;
  const vars = {
    HYDRA_LEAKS_EXCEPTIONS: ['_nextSetupHydrationWarning', '$reactTemp', '_gsap'],
    HYDRA_BASE_PATH: router.basePath,
    HYDRA_ASSETS_BASE_PATH: basePath,
    HYDRA_ASSETS_CDN: basePath,
    HYDRA_MOBILE_SCROLL: true,
    UIL_ID: process.env.HYDRA_UIL_ID,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _CACHE_: process.env.HYDRA_CACHE_ID,
    UIL_STATIC_PATH: `${basePath}${process.env.HYDRA_UIL_STATIC_PATH || 'assets/data/uil.json'}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _PROJECT_NAME_: process.env.HYDRA_PROJECT_NAME,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _THREAD_PATH_: `${basePath}assets/js/hydra`,
  };
  const js = `${basePath}assets/js/app.${process.env.HYDRA_CACHE_ID}.js`;
  useRouterEvents();
  return (
    <>
      <SafeScript id="hydraConfig" strategy="beforeInteractive">
        {Object.entries(vars)
          .map(([key, value]) => `window.${key}=${JSON.stringify(value)}`)
          .join(';')}
      </SafeScript>
      {isLocal ? (
        <>
          <SafeScript src={`${basePath}assets/runtime/boot.js`} strategy="beforeInteractive" />
          <SafeScript src={`${basePath}assets/runtime/dev.js`} strategy="beforeInteractive" />
        </>
      ) : (
        <SafeScript src={js} async />
      )}
    </>
  );
}
