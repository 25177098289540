/**
 * @author @Patreon/fe-core
 **/

import { isClient } from 'shared/environment';
import type { PartialExceptFor } from 'types/utilities';

const ssrWindow: PartialExceptFor<
  Window,
  'addEventListener' | 'removeEventListener' | 'scrollTo' | 'open' | 'confirm'
> = {
  addEventListener: () => undefined,
  removeEventListener: () => undefined,
  scrollTo: () => undefined,
  open: () => null,
  confirm: () => true,
};

const getWindow = (client: boolean = isClient()): typeof ssrWindow => (client ? window : ssrWindow);
export default getWindow;
