import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { onCLS, onFCP, onFID, onLCP, onTTFB, onINP } from 'web-vitals';

import { logWebVitalsEvent } from '@/analytics/logger/generatedEvents/webVitalsEvent';

let bound = false;

function bindWebVitals(urlRule: string) {
  if (bound) {
    return;
  }

  bound = true;

  function logWebVital({ name, value }: { name: string; value: number }) {
    logWebVitalsEvent({
      value,
      metric: name,
      route: urlRule,
    });
  }

  onCLS(logWebVital);
  onFCP(logWebVital);
  onFID(logWebVital);
  onLCP(logWebVital);
  onTTFB(logWebVital);
  onINP(logWebVital);
}

export function useWebVitals() {
  const router = useRouter();
  const urlRule = router.asPath;

  useEffect(() => {
    bindWebVitals(urlRule);
  }, [urlRule]);
}
