import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';

import 'nprogress/nprogress.css';

export default function useRouterProgressBar() {
  const { events } = useRouter();

  useEffect(() => {
    NProgress.configure({
      showSpinner: false,
      trickle: true,
      trickleSpeed: 200,
      minimum: 0.08,
      easing: 'ease',
      speed: 200,
    });

    const start = () => {
      NProgress.start();
    };
    const done = () => {
      NProgress.done();
      document.getElementById('main-content')?.focus();
    };
    events.on('routeChangeStart', start);
    events.on('routeChangeComplete', done);
    events.on('routeChangeError', done);

    return () => {
      events.off('routeChangeStart', start);
      events.off('routeChangeComplete', done);
      events.off('routeChangeError', done);
    };
  }, [events]);
}
