import { getSessionItem, removeSessionItem, setSessionItem } from 'utilities/session-storage';

const getExpirationDate = () => {
  let now = new Date();

  // Returns a date that is exactly 24 hours after current date
  return now.setHours(now.getHours() + 24);
};

const removeExpiredMetaData = () => {
  const expirationDate = JSON.parse(getSessionItem('metaDataExpiration') || '{}');
  const now = new Date();

  if (now.getTime() > expirationDate) {
    removeSessionItem('expirationDate');
    removeSessionItem('trackingMetaData');
  }
};

export const setTrackingMetaData = (requestMetaData) => {
  removeExpiredMetaData();

  const sessionMetaData = JSON.parse(getSessionItem('trackingMetaData') || '{}') || {};

  const sessionMetaDataExists = Object.keys(sessionMetaData).length > 0;

  const isValidNewData = !!requestMetaData.referrer_url;
  const isNewReferrer = sessionMetaData.referrer_url !== requestMetaData.referrer_url;
  // Update the tracking data if:
  // - There is no existing tracking data
  // - OR User arrived from a new non-Patreon HTTP referer
  if (!sessionMetaDataExists || (isValidNewData && isNewReferrer)) {
    setSessionItem('trackingMetaData', JSON.stringify(requestMetaData));
    setSessionItem('metaDataExpiration', JSON.stringify(getExpirationDate()));
  }
};

export const getTrackingMetaData = () => JSON.parse(getSessionItem('trackingMetaData') || '{}');
