import windowOrFixture from './get-window/index';

const windowObj = windowOrFixture();

let canWriteToSessionStorage = false;

const canAccessSessionStorage = () => {
  if (canWriteToSessionStorage) {
    return true;
  }

  try {
    const sessionStorage = windowObj.sessionStorage || null;

    if (!sessionStorage) {
      canWriteToSessionStorage = false;
      return false;
    }

    sessionStorage.setItem('patreon-session-access', 'patreon');
    sessionStorage.removeItem('patreon-session-access');
    canWriteToSessionStorage = true;
    return true;
  } catch (exception) {
    canWriteToSessionStorage = false;
    return false;
  }
};

export const getSessionStorage = () => canAccessSessionStorage() && windowObj.sessionStorage;

export const removeSessionItem = (key: string) => {
  const ss = getSessionStorage();
  if (ss) {
    return ss.removeItem(key);
  }
};

export const getSessionItem = (key: string) => {
  const ss = getSessionStorage();
  if (ss) {
    return ss.getItem(key);
  }
  return undefined;
};

export const setSessionItem = (key: string, val: string) => {
  const ss = getSessionStorage();
  if (ss) {
    return ss.setItem(key, val);
  }
};

export default getSessionStorage;
