/**
 * @author @Patreon/data-eng
 **/

export class ConsoleLogger {
  // TODO (legacied @typescript-eslint/explicit-member-accessibility)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  isLoggingEnabled: boolean;

  constructor(isLoggingEnabled: boolean) {
    this.isLoggingEnabled = isLoggingEnabled;
  }

  // TODO (legacied @typescript-eslint/explicit-member-accessibility)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  logToConsole(
    name: string,
    eventProperties?: Record<string, unknown>,
    userProperties?: Record<string, unknown>,
    opts?: Record<string, unknown>,
  ): void {
    // TODO (legacied no-unused-expressions)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line no-unused-expressions
    this.isLoggingEnabled &&
      typeof console !== 'undefined' &&
      // TODO (legacied no-console)
      // This failure is legacied in and should be updated. DO NOT COPY.
      // eslint-disable-next-line no-console
      console.log('[log]', name, eventProperties, userProperties, opts);
  }
}
