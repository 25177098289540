import { proxy, ref } from 'valtio';
import * as utils from 'valtio/utils';

export interface HydraNextBusComponent extends EventTarget {
  // eslint-disable-next-line @typescript-eslint/ban-types
  state: object;
}

class HydraNextBusComponentImpl extends EventTarget implements HydraNextBusComponent {
  // eslint-disable-next-line @typescript-eslint/ban-types
  public state: object;
  constructor() {
    super();
    this.state = proxy({});
  }
}

export interface HydraNextBus {
  next: HydraNextBusComponent;
  hydra: HydraNextBusComponent;
  utils: typeof utils;
  ref: typeof ref;
}

class HydraNextBusImpl implements HydraNextBus {
  public next: HydraNextBusComponent;
  public hydra: HydraNextBusComponent;
  public utils: typeof utils;
  public ref: typeof ref;

  constructor() {
    this.next = new HydraNextBusComponentImpl();
    this.hydra = new HydraNextBusComponentImpl();
    this.utils = utils;
    this.ref = ref;
  }
}

let instance: HydraNextBus;

function getHydraNextBus() {
  if (!instance) {
    instance = new HydraNextBusImpl();
  }
  return instance;
}

export default getHydraNextBus;
