import { noop } from 'lodash';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { DependencyList, EffectCallback } from 'react';
import React, { useEffect } from 'react';

import { logCookieCollectionBannerRenderedEvent } from '@/analytics/logger/generatedEvents/cookieCollectionBannerRenderedEvent';
import { isDevelopment } from '@/shared/environment';

declare global {
  interface Window {
    transcend?: UninitializedTranscend & {
      showConsentManager?: ({ viewState }: { viewState: 'CompleteOptions' }) => void;
    };
  }
}

export default function TranscendScript() {
  useTranscendImpressionLogging();

  const router = useRouter();

  return (
    <Script
      strategy="beforeInteractive"
      data-cfasync="false"
      // On the cookie policy page, we want to disable auto-prompting since it
      // introduces a race condition where the banner is hidden if autoshown
      // when the auto-prompt logic is handled.
      data-prompt={isCookiePolicyPage(router) ? 'off' : 'auto'}
      src={
        isDevelopment()
          ? 'https://transcend-cdn.com/cm-test/8dec6ba2-a601-4c04-a3ba-e4d5b45000f4/airgap.js'
          : 'https://transcend-cdn.com/cm/8dec6ba2-a601-4c04-a3ba-e4d5b45000f4/airgap.js'
      }
    />
  );
}

function isCookiePolicyPage(router: NextRouter) {
  const slug = router?.query?.slug || [];
  return slug.length === 2 && slug[0] === 'policy' && slug[1] === 'cookies';
}

// Taken from patreon_react_features/app/analytics/components/TranscendLogger/index.tsx
function useTranscendImpressionLogging() {
  useSafeEffect(() => {
    if (!window.transcend) {
      // Stub transcend with a pre-init API.
      window.transcend = {
        readyQueue: [],
        ready(callback: (transcend: InitializedTranscend) => void) {
          this.readyQueue.push(callback);
        },
        ...(window.transcend || {}),
      };
    }

    let isMounted = true;
    let initializedTranscend: InitializedTranscend | undefined;

    window.transcend.ready((transcend) => {
      if (!isMounted) {
        // If we've already unmounted before Transcend is ready, don't add an event listener.
        return;
      }

      initializedTranscend = transcend;

      initializedTranscend.addEventListener('view-state-change', viewStateChangeListener);
    });

    return () => {
      isMounted = false;

      if (!initializedTranscend) {
        // If we haven't yet initialized Transcend yet, we don't need to remove the listener.
        return;
      }

      initializedTranscend.removeEventListener('view-state-change', viewStateChangeListener);
    };
  }, []);
}

/** A listener for Transcend view-state-change events that logs when the banner becomes visible. */
const viewStateChangeListener = (event: TranscendEventMap['view-state-change']) => {
  const { viewState } = event.detail;
  if (viewState !== 'Hidden') {
    logCookieCollectionBannerRenderedEvent({
      cookie_collection_vendor: 'transcend',
      web_repo_context: 'patreon_marketing',
    });
  }
};

// Adapted from patreon_react_features/app/utilities/hooks/useSafeEffect.ts
function useSafeEffect(callback: EffectCallback, deps?: DependencyList) {
  useEffect(() => {
    try {
      const cleanupFunction = callback();
      if (!cleanupFunction) {
        return noop;
      }

      return () => {
        try {
          cleanupFunction();
        } catch (error) {
          /* empty */
        }
      };
    } catch (error) {
      return noop;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

type TranscendViewState = 'Hidden' | 'CompleteOptions' | 'AcceptOrRejectAllOrMoreChoices';

interface TranscendEventMap {
  'view-state-change': CustomEvent<{ viewState: TranscendViewState; previousViewState: TranscendViewState }>;
}

interface UninitializedTranscend {
  readyQueue: Array<(transcend: InitializedTranscend) => void>;
  ready: (callback: (transcend: InitializedTranscend) => void) => void;
}

interface InitializedTranscend extends EventTarget {
  addEventListener<K extends keyof TranscendEventMap>(
    type: K,
    listener: (this: InitializedTranscend, ev: TranscendEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener<K extends keyof TranscendEventMap>(
    type: K,
    listener: (this: InitializedTranscend, ev: TranscendEventMap[K]) => void,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
}
