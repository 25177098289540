// import ls from 'local-storage';
import stub from 'local-storage/stub';

// local-storage doesn’t “just work” like it says in its README when user
// blocks access to storage!
// https://github.com/bevacqua/local-storage/issues/28

let impl = stub;
try {
  // An exception is thrown as soon as the local-storage module is evaluated,
  // because the very attempt to read the window.localStorage property throws a
  // SecurityException.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  impl = require('local-storage');
} catch (e) {
  // ignore
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function get(key: string): any {
  try {
    return impl.get(key);
  } catch (e) {
    if (impl !== stub) {
      impl = stub;
      return get(key);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function set(key: string, value: any): boolean {
  try {
    return impl.set(key, value);
  } catch (e) {
    if (impl !== stub) {
      impl = stub;
      return set(key, value);
    }
  }
  return false;
}
