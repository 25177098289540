// THIS CODE WAS GENERATED. DO NOT ALTER.
// COMMAND USED TO GENERATE: ./scripts/event_logging/create_event.py --event web_vitals_event
// <<SIGNATURE=85ffc03f88c30563132cffde58e84ed4>>
import { patreonTrackerClient } from '..';

interface EventType {
  metric: string;
  value: number;
  route: string;
}

export function logWebVitalsEvent(data: EventType) {
  patreonTrackerClient.logTypedEvent('Web Vitals', data);
}
