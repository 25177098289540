type Getter<T> = () => T | undefined;

// eslint-disable-next-line import/export
export function get<T, U>(getterFn: Getter<T>, defaultValue: U): T | U;
// eslint-disable-next-line import/export
export function get<T>(getterFn: Getter<T>, defaultValue?: undefined): T | undefined;
// eslint-disable-next-line import/export
export function get<T, U>(getterFn: Getter<T>, defaultValue?: U): T | U | undefined {
  try {
    const value = getterFn();
    return value !== undefined ? value : defaultValue;
  } catch (error) {
    if (error instanceof TypeError) {
      return defaultValue;
    }
    throw error;
  }
}
