/**
 * @author @Patreon/fe-core
 **/

const DEVELOPMENT = 'development';
const PRODUCTION = 'production';
const TEST = 'test';

const nodeEnv: string = process.env.NODE_ENV || DEVELOPMENT;

export const isClient = () => typeof window !== 'undefined';
export const isDevelopment = () => nodeEnv === DEVELOPMENT;
export const isProduction = () => nodeEnv === PRODUCTION;
export const isTest = () => nodeEnv === TEST;
export const isPreview = process.env.NEXT_PUBLIC_IS_PREVIEW;

export const getRuntimeEnvironment = () => nodeEnv;

export const shouldRenderStrapiDraft = () => process.env.NEXT_PUBLIC_INCLUDE_DRAFTS || isPreview || isDevelopment();
