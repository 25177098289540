import type Lenis from '@studio-freight/lenis';
import { useLenis } from '@studio-freight/react-lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect } from 'react';

import { useSnapshot, ref as valtioRef } from 'valtio';

import getHydraNextBus from '@/hydra/next/getHydraNextBus';

type RenderCallback = (time: number) => void;

interface HydraWorld {
  startRender: (callback: RenderCallback, slot?: string) => void;
  stopRender: (callback: RenderCallback, slot?: string) => void;
}

const FRAME_BEGIN = 'RenderManager_frame_begin';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
  // ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })
}

let velocity: number;
let lastScrollY: number;
let lastUpdate: number;

function getIsScrolling(lenis: Lenis, time: number): boolean {
  if (lenis.isSmooth) {
    return !!lenis.isScrolling;
  }
  // When Lenis is not in smooth-scrolling mode (i.e. on mobile,
  // because the device renders scrolling at a higher frame rate
  // than js/webgl content), it doesn’t report isScrolling, so
  // we need to calculate it ourselves.
  if (typeof velocity === 'undefined') {
    velocity = 0;
  } else {
    const distance = Math.abs(lenis.animatedScroll - lastScrollY);
    const delta = time - lastUpdate;
    const targetVelocity = distance / delta;
    velocity += (targetVelocity - velocity) * 0.2;
  }
  lastUpdate = time;
  lastScrollY = lenis.animatedScroll as number;
  return velocity > 0.01;
}

export default function HydraLenis() {
  const lenis = useLenis() as Lenis | undefined;
  const snap: { world?: HydraWorld } = useSnapshot(getHydraNextBus().hydra.state);

  useEffect(() => {
    const state = getHydraNextBus().next.state as { lenis?: Lenis; isScrolling?: boolean };
    state.lenis = lenis && valtioRef(lenis);
    if (!lenis) {
      return;
    }
    state.isScrolling = getIsScrolling(lenis, 0);

    lenis.on('scroll', () => ScrollTrigger.update());

    let update: RenderCallback;

    if (snap.world) {
      update = (time: number) => {
        lenis.raf(time);
        state.isScrolling = getIsScrolling(lenis, time);
      };
      snap.world.startRender(update, FRAME_BEGIN);
    } else {
      update = (time: number) => {
        time *= 1000;
        lenis.raf(time);
        state.isScrolling = getIsScrolling(lenis, time);
      };
      gsap.ticker.add(update);
    }
    return () => {
      if (snap.world?.stopRender) {
        snap.world.stopRender(update, FRAME_BEGIN);
      } else {
        gsap.ticker.remove(update);
      }
    };
  }, [snap.world, lenis]);

  useEffect(() => {
    if (lenis) {
      ScrollTrigger.refresh();
      lenis?.start();
    }
  }, [lenis]);

  return null;
}
