// THIS CODE WAS GENERATED. DO NOT ALTER.
// COMMAND USED TO GENERATE: ./scripts/event_logging/create_event.py --event cookie_collection_banner_rendered_event
import { patreonTrackerClient } from '..';
import type { CookieCollectionVendor, WebRepoContext } from './analyticsEnums';

interface EventType {
  cookie_collection_vendor: CookieCollectionVendor;
  web_repo_context: WebRepoContext;
}

export function logCookieCollectionBannerRenderedEvent(data: EventType) {
  patreonTrackerClient.logTypedEvent('Cookie Collection Banner : Landed', data);
}
