/**
 * @author @Patreon/fe-core
 **/

import { isClient, isTest } from 'shared/environment';
import { getTrackingMetaData, setTrackingMetaData } from 'shared/events/tracking-meta-data';
import { get } from 'utilities/get';
import getWindow from 'utilities/get-window';

import { eventsWithUploadTime, enrichedSessionData } from './analytics-enrichment';
import { ConsoleLogger } from './console-logger';
import * as ls from './local-storage';
import type { EventType, EventWithUploadTimeType } from './types';

export enum LogTransport {
  XHR = 'XHR',
  Beacon = 'Beacon',
}

function indexOfFirstMatch(arr: string[], key: string | RegExp) {
  const regEx = new RegExp(key);
  for (let i = 0; i < arr.length; i++) {
    if (regEx.test(arr[i])) {
      return i;
    }
  }
  return -1;
}

export const getParsedReferrerData = (referrerUrl: string | null) => {
  const formattedReferrerUrl = referrerUrl && !referrerUrl.startsWith('http') ? `http://${referrerUrl}` : referrerUrl;
  const parsedUrl = formattedReferrerUrl ? new URL(formattedReferrerUrl) : null;
  const notReferredFromPatreon =
    parsedUrl?.hostname &&
    !parsedUrl.hostname.endsWith('patreon.com') &&
    !parsedUrl.hostname.endsWith('patreondev.com');

  return {
    referrer_url: referrerUrl && notReferredFromPatreon ? referrerUrl : null,
  };
};

export function getUTMData() {
  const currentUrl = getWindow().location?.href;
  if (!currentUrl) {
    return;
  }
  // Legacy code, needs to be updated to comply with more strict typing rules
  // TODO (legacied @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  let utmParams = get(() => getTrackingMetaData().utmParams);
  // TODO (legacied @typescript-eslint/no-unsafe-argument)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (utmParams && Object.entries(utmParams).length > 0) {
    // TODO (legacied @typescript-eslint/no-unsafe-return)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return utmParams;
  }

  utmParams = {};

  function addUtmParam(key: string) {
    const regEx = new RegExp(key + '=');
    if (currentUrl && regEx.test(currentUrl)) {
      const params = currentUrl.split('=');
      const index = indexOfFirstMatch(params, key);
      const value = params[index + 1].split('&')[0];
      if (value !== undefined) {
        // Legacy code, needs to be updated to comply with more strict typing rules
        // TODO (legacied @typescript-eslint/no-unsafe-member-access)
        // This failure is legacied in and should be updated. DO NOT COPY.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        utmParams[key] = value;
      }
    }
  }

  addUtmParam('utm_source');
  addUtmParam('utm_medium');
  addUtmParam('utm_campaign');
  addUtmParam('utm_term');
  addUtmParam('utm_content');
  // Legacy code, needs to be updated to comply with more strict typing rules
  // TODO (legacied @typescript-eslint/no-unsafe-return)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return utmParams;
}

function getDestinationConfig() {
  const baseEndpoint = 'https://www.patreon.com';

  const destinations = {
    PatreonApi: {
      trackingEndpoint: baseEndpoint + '/api/tracking',
      localStorageEventKey: 'patreon-tracking',
    },
    PatreonInternalApi: {
      trackingEndpoint: baseEndpoint + '/api/tracking_internal',
      localStorageEventKey: 'patreon-internal-tracking',
    },
  };

  return destinations;
}

export class PatreonTrackerDestination {
  // Millisecond batching period
  private batchPeriod = 3000;
  private unsentEvents: EventType[] = [];
  private hasUploadScheduled = false;
  private isSending = false;
  private trackingEndpoint: string;
  private localStorageEventKey: string;
  private consoleLogger: ConsoleLogger;

  constructor(destination: { trackingEndpoint: string; localStorageEventKey: string }) {
    this.trackingEndpoint = destination.trackingEndpoint;
    this.localStorageEventKey = destination.localStorageEventKey;
    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const storedEvents: EventType[] = ls ? ls.get(this.localStorageEventKey) : [];

    if (Array.isArray(storedEvents)) {
      this.unsentEvents = storedEvents;
      this.clearStoredEvents();
    }

    this.consoleLogger = new ConsoleLogger(isLoggerFunctionalityEnabled('log', 'log'));
  }

  public addEventListeners() {
    if (this.canSendViaBeacon()) {
      this.addBeaconEventListeners();
    }
  }

  public removeEventListeners() {
    if (this.canSendViaBeacon()) {
      this.removeBeaconEventListeners();
    }
  }

  public logTypedEvent = <T>(eventType: string, payload: T) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment
    const { referrer_url, utmParams } = getTrackingMetaData();

    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const referrerData = referrer_url ? { referrer_url } : {};

    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const eventPayload = {
      ...payload,
      ...referrerData,
      ...utmParams,
      ...enrichedSessionData(),
      is_client_et: true,
      client_event_time: new Date().getTime(),
    };
    const event: EventType = {
      event_type: eventType,
      // Legacy code, needs to be updated to comply with more strict typing rules
      // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
      // This failure is legacied in and should be updated. DO NOT COPY.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      payload: eventPayload,
    };

    this.queueEvent(event);
    this.sendEvents();
    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied  @typescript-eslint/no-unsafe-argument)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.consoleLogger.logToConsole(event.event_type, eventPayload);
  };

  private removeBeaconEventListeners() {
    if (document && document.removeEventListener) {
      document.removeEventListener?.('visibilitychange', this.handleVisibilityChange);
      document.removeEventListener?.('pagehide', this.logNowWithBeacon);
    }
  }

  private logNowWithBeacon = () => {
    if (this.isReadyToSend()) {
      this.sendEventsToServer(LogTransport.Beacon);
    }
  };

  private queueEvent = (event: EventType) => {
    this.unsentEvents.push(event);
    this.saveEvents();
  };

  private canSendViaBeacon() {
    return typeof getWindow().navigator?.sendBeacon === 'function';
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.logNowWithBeacon();
    }
  };

  private addBeaconEventListeners() {
    if (document && document.addEventListener) {
      document.addEventListener?.('visibilitychange', this.handleVisibilityChange, true);
      document.addEventListener?.('pagehide', this.logNowWithBeacon, true);
    }
  }

  private saveEvents = () => {
    if (ls) {
      ls.set(this.localStorageEventKey, this.unsentEvents);
    }
  };

  private clearStoredEvents = () => {
    if (ls) {
      ls.set(this.localStorageEventKey, []);
    }
  };

  private sendEvents = () => {
    if (this.hasUploadScheduled) {
      return;
    }

    this.hasUploadScheduled = true;
    setTimeout(() => {
      this.hasUploadScheduled = false;
      this.sendEventsToServer(LogTransport.XHR);
    }, this.batchPeriod);
  };

  private clearSentEvents = (numEventsPosted: number) => {
    for (let i = 0; i < numEventsPosted; i++) {
      this.unsentEvents.shift();
    }
    this.saveEvents();
  };

  private sendEventsToServerViaXHR = (events: EventWithUploadTimeType[]) => {
    const numEventsToPost = events.length;
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', this.trackingEndpoint);
    /* Required for sending cookies */
    xmlhttp.withCredentials = true;
    xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

    xmlhttp.send(JSON.stringify(events));

    xmlhttp.onload = () => {
      if (xmlhttp.readyState === xmlhttp.DONE) {
        if (xmlhttp.status === 200) {
          this.clearSentEvents(numEventsToPost);
        }
        this.isSending = false;
      }
    };
  };

  private sendEventsToServerViaBeacon = (events: EventWithUploadTimeType[]) => {
    const numEventsToPost = events.length;
    const sent =
      this.canSendViaBeacon() && getWindow().navigator?.sendBeacon(this.trackingEndpoint, JSON.stringify(events));
    if (sent) {
      this.clearSentEvents(numEventsToPost);
    }
    this.isSending = false;
  };

  private isReadyToSend = () => getWindow().navigator?.onLine;

  private sendEventsToServer = (transport: LogTransport) => {
    if (this.isSending) {
      return;
    }

    const events = eventsWithUploadTime(this.unsentEvents, new Date().getTime());
    if (events.length === 0) {
      return;
    }

    this.isSending = true;

    if (isClient() && !isTest()) {
      if (transport === LogTransport.Beacon) {
        this.sendEventsToServerViaBeacon(events);
      } else {
        this.sendEventsToServerViaXHR(events);
      }
    }
  };
}

const isLoggerFunctionalityEnabled = (lsKey: string, urlParam: string, defaultEnabled = false): boolean => {
  const urlParamEnabled = getWindow().location?.search?.includes(`${urlParam}=1`);

  const urlParamDisabled = getWindow().location?.search?.includes(`${urlParam}=0`);

  if (urlParamEnabled) {
    if (ls) {
      ls.set(lsKey, true);
    }
    return true;
  } else if (urlParamDisabled) {
    if (ls) {
      ls.set(lsKey, false);
    }
    return false;
  }

  // Legacy code, needs to be updated to comply with more strict typing rules
  // TODO (legacied @typescript-eslint/no-unsafe-return)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (ls && ls.get(lsKey)) ?? defaultEnabled;
};

export class PatreonTrackerClient {
  public patreonApiDestination: PatreonTrackerDestination | null = null;
  public patreonInternalApiDestination: PatreonTrackerDestination | null = null;
  private loggedEventsCache = new Set();

  public init = () => {
    if (!isClient()) {
      // Avo should only run on the client-side.
      return;
    }
    const destinationConfig = getDestinationConfig();
    this.patreonApiDestination = new PatreonTrackerDestination(destinationConfig.PatreonApi);
    this.patreonInternalApiDestination = new PatreonTrackerDestination(destinationConfig.PatreonInternalApi);

    const referrerData = getParsedReferrerData(getWindow().document?.referrer ?? null);

    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const utmParams = getUTMData();

    const metaProperties = {
      ...referrerData,
      // Legacy code, needs to be updated to comply with more strict typing rules
      // TODO (legacied  @typescript-eslint/no-unsafe-assignment)
      // This failure is legacied in and should be updated. DO NOT COPY.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      utmParams,
    };

    setTrackingMetaData(metaProperties);
  };

  public logTypedEvent = <T>(event: string, payload?: T) => {
    if (!isClient()) {
      // Logger should only run on the client
      return;
    }
    this.patreonApiDestination?.logTypedEvent(event, payload);
  };

  public logTypedEventOnce = <T>(event: string, payload?: T) => {
    if (this.loggedEventsCache.has(event)) {
      return;
    }
    this.logTypedEvent(event, payload);
    this.loggedEventsCache.add(event);
  };
}
