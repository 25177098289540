/**
 * @author @Patreon/data-eng
 **/

import { v4 as uuidv4 } from 'uuid';

import getWindow from 'utilities/get-window';

import { getSessionItem, setSessionItem } from 'utilities/session-storage';

import * as ls from './local-storage';

import type { EventType, TypedLogEventType } from './types';

const LOGGER_EVENT_INDEX_STORAGE_KEY = 'p_log_index';
const LOGGER_TAB_ID_STORAGE_KEY = 'p_log_tab_id';
const SESSION_ACTIVITY_ID_STORAGE_KEY = 'p_log_session_activity_id';
const SESSION_ACTIVITY_EVENT_INDEX_STORAGE_KEY = 'p_log_session_activity_event_index';
const SESSION_ACTIVITY_ID_EXPIRATION_STORAGE_KEY = 'p_log_session_activity_id_expiration';
const ACTIVITY_ID_EXPIRATION_MILLISECONDS = 30 * 60 * 1000;

let eventIndex: number | null = null;
let tabID: string | null = null;
let sessionActivityID: string | null = null;
let sessionActivityIDExpiration = 0;
let expiration = 0;
let pageSessionId: string | null = null;

const getSessionActivityID = () => {
  // A UUID that expires after 30 minutes of inactivity
  // Shared across all tabs in the browser

  const currentTime = new Date().getTime();
  // check if we can just use what's in memory
  if (sessionActivityID && sessionActivityIDExpiration && currentTime < sessionActivityIDExpiration) {
    setActivityIDExpiration(currentTime);
    return sessionActivityID;
  }

  // otherwise read from localStorage

  try {
    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sessionActivityID = ls.get(SESSION_ACTIVITY_ID_STORAGE_KEY);
    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    expiration = ls.get(SESSION_ACTIVITY_ID_EXPIRATION_STORAGE_KEY) ?? 0;
    // TODO (legacied no-empty)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line no-empty
  } catch (err) {}
  if (currentTime > expiration) {
    sessionActivityID = createSessionID();
    ls.set(SESSION_ACTIVITY_ID_STORAGE_KEY, sessionActivityID);
    setSessionActivityEventIndex(-1);
  }
  setActivityIDExpiration(currentTime);
  return sessionActivityID;
};

const setActivityIDExpiration = (currentTime: number) => {
  sessionActivityIDExpiration = currentTime + ACTIVITY_ID_EXPIRATION_MILLISECONDS;
  ls.set(SESSION_ACTIVITY_ID_EXPIRATION_STORAGE_KEY, sessionActivityIDExpiration);
};

const getTabID = () => {
  // A UUID that expires whenever the tab is closed
  // Unique to this tab
  const oldTabID = tabID;
  tabID = tabID ?? getSessionItem(LOGGER_TAB_ID_STORAGE_KEY) ?? createSessionID();

  if (tabID !== oldTabID) {
    setSessionItem(LOGGER_TAB_ID_STORAGE_KEY, tabID);
  }

  return tabID;
};

const getPageSessionId = () => {
  pageSessionId = pageSessionId ? pageSessionId : createSessionID();
  return pageSessionId;
};

const createSessionID = () => uuidv4();

const getEventIndex = (): number => +(eventIndex ?? getSessionItem(LOGGER_EVENT_INDEX_STORAGE_KEY) ?? -1);

const getAndIncrementSessionActivityEventIndex = () => {
  let sessionActivityEventIndex = -1;
  try {
    // Legacy code, needs to be updated to comply with more strict typing rules
    // TODO (legacied @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sessionActivityEventIndex = ls.get(SESSION_ACTIVITY_EVENT_INDEX_STORAGE_KEY) ?? -1;
    // TODO (legacied no-empty)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line no-empty
  } catch (err) {}
  setSessionActivityEventIndex(++sessionActivityEventIndex);
  return sessionActivityEventIndex;
};

const setSessionActivityEventIndex = (sessionActivityEventIndex: number) => {
  ls.set(SESSION_ACTIVITY_EVENT_INDEX_STORAGE_KEY, sessionActivityEventIndex);
};

const incrementEventIndex = () => {
  // TODO (legacied no-unused-expressions)
  // This failure is legacied in and should be updated. DO NOT COPY.
  // eslint-disable-next-line no-unused-expressions
  eventIndex !== null && eventIndex++;
  if (typeof eventIndex === 'number') {
    setSessionItem(LOGGER_EVENT_INDEX_STORAGE_KEY, eventIndex.toString());
  }
};

const getAndIncrementEventIndex = () => {
  eventIndex = getEventIndex();
  incrementEventIndex();
  return eventIndex;
};

export const enrichedSessionData = (): Record<string, unknown> => {
  if (!ls) {
    return {};
  }
  return {
    event_index: getAndIncrementEventIndex(),
    tab_id: getTabID(),
    page_session_id: getPageSessionId(),
    session_activity_id: getSessionActivityID(),
    session_activity_event_index: getAndIncrementSessionActivityEventIndex(),
    origin_pathname: getWindow()?.location?.pathname,
    is_next: true,
    source_repo: 'patreon_marketing',
  };
};

// export const enrichWithSessionData = (
//   event: LegacyEventType
// ): LegacyEventType => {
//   if (!ls) {
//     return event;
//   }
//   event.event_properties = {
//     ...event.event_properties,
//     ...enrichedSessionData(),
//   };

//   return event;
// };

// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
const isTypedEvent = (event: EventType): event is TypedLogEventType<Record<string, unknown>> =>
  (event as TypedLogEventType<Record<string, unknown>>).payload !== undefined;

const eventWithUploadTime = (event: EventType, uploadTime: number) => {
  if (isTypedEvent(event)) {
    const payload = event.payload;
    return {
      event_type: event.event_type,
      payload: { ...payload, client_upload_time: uploadTime },
    };
  }
  return {
    ...event,
    client_upload_time: uploadTime,
  };
};

export const eventsWithUploadTime = (events: EventType[], uploadTime: number) =>
  events.map((event) => eventWithUploadTime(event, uploadTime));
